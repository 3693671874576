import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import axiosInstance from 'apps/apis';
import {
  PACKAGE_REVIEWS,
  REVIEWS,
  ROOM_REVIEWS,
  TESTIMONIES,
  WORKER_REVIEWS,
  WORKER_TESTIMONIES
} from 'apps/apis/allApis';
import extractError from '@myservice/helper/extractError';
import {useNotificationMethod} from '@myservice/hooks';

const ReviewContext = createContext();
const ReviewActionsContext = createContext();

const ReviewProvider = ({children}) => {
  const [reviewLoading, setReviewLoading] = useState(false);
  const [testimonies, setTestimonies] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [workerReview, setWorkerReviews] = useState([]);
  const [workerTestimonies, setWorkerTestimonies] = useState([]);

  const {showNotification} = useNotificationMethod();

  const postStartReview = async (reviews) => {
    setReviewLoading(true);
    try {
      const resp = await axiosInstance.post(REVIEWS, reviews);
      setReviewLoading(false);
      return resp;
    } catch (error) {
      setReviewLoading(false);
      console.error(error);
      showNotification(extractError(error.response.data));
    }
  };

  const postRoomReview = async (reviews) => {
    setReviewLoading(true);
    try {
      const resp = await axiosInstance.post(ROOM_REVIEWS, reviews);
      setReviewLoading(false);
      showNotification(
        'Thank you for taking the time to add your review! Your feedback is invaluable to us.',
        'success',
        'Review Submitted Successfully'
      );
      return resp;
    } catch (error) {
      console.error(error);
      showNotification(extractError(error.response.data));
    } finally {
      setReviewLoading(false);
    }
  };

  const postPackageReview = async (reviews) => {
    setReviewLoading(true);
    try {
      const resp = await axiosInstance.post(PACKAGE_REVIEWS, reviews);
      setReviewLoading(false);
      showNotification(
        'Thank you for taking the time to add your review! Your feedback is invaluable to us.',
        'success',
        'Review Submitted Successfully'
      );
      return resp;
    } catch (error) {
      console.error(error);
      showNotification(extractError(error.response.data));
    } finally {
      setReviewLoading(false);
    }
  };

  const patchStartReview = async (reviews) => {
    const patchId = reviews.id;
    delete reviews.id;
    setReviewLoading(true);
    try {
      const resp = await axiosInstance.put(`${REVIEWS}${patchId}`, reviews);
      setReviewLoading(false);
      return resp;
    } catch (error) {
      setReviewLoading(false);
      console.log('error', error);
      showNotification(extractError(error.response.data));
    }
  };

  const getAllTestimonies = async () => {
    setReviewLoading(true);
    try {
      const {data} = await axiosInstance.get(TESTIMONIES);
      setReviewLoading(false);
      setTestimonies(data);
    } catch (error) {
      setReviewLoading(false);
      console.log('error', error);
      showNotification(extractError(error.response.data));
    }
  };

  const getAllReviews = async () => {
    setReviewLoading(true);
    try {
      const {data} = await axiosInstance.get(REVIEWS);
      setReviewLoading(false);
      setReviews(data);
    } catch (error) {
      setReviewLoading(false);
      console.log('error', error);
      showNotification(extractError(error.response.data));
    }
  };

  const getWorkerReviews = async (id) => {
    setReviewLoading(true);
    try {
      const {data} = await axiosInstance.get(`${WORKER_REVIEWS}${id}`);
      setReviewLoading(false);
      setWorkerReviews(data);
    } catch (error) {
      setReviewLoading(false);
      console.log('error', error);
      showNotification(extractError(error.response.data));
    }
  };

  const getWorkerTestimonies = async (id) => {
    setReviewLoading(true);
    try {
      const {data} = await axiosInstance.get(`${WORKER_TESTIMONIES}${id}`);
      setReviewLoading(false);
      setWorkerTestimonies(data);
    } catch (error) {
      setReviewLoading(false);
      console.error('testimonies error', error);
      showNotification(extractError(error.response.data));
    }
  };

  return (
    <ReviewContext.Provider
      value={{
        reviewLoading,
        testimonies,
        workerReview,
        workerTestimonies,
        reviews
      }}
    >
      <ReviewActionsContext.Provider
        value={{
          postPackageReview,
          postRoomReview,
          postStartReview,
          patchStartReview,
          getAllTestimonies,
          getAllReviews,
          getWorkerReviews,
          getWorkerTestimonies
        }}
      >
        {children}
      </ReviewActionsContext.Provider>
    </ReviewContext.Provider>
  );
};

export const useReviewContext = () => useContext(ReviewContext);
export const useReviewActionContext = () => useContext(ReviewActionsContext);

ReviewProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ReviewProvider;
