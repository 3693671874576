import {
  useAccommodationContext,
  useAccommodationActions
} from 'apps/accommodation/AccommodationProvider';

export const useAccommodation = () => {
  const {accommodationState, featureAccommodation, loading, cities} =
    useAccommodationContext();
  return {
    accommodationState,
    featureAccommodation,
    loading,
    cities
  };
};

export const useAccommodationMethod = () => {
  const {getAccommodations, getFeatureAccommodations, getAllCites} =
    useAccommodationActions();
  return {getAccommodations, getFeatureAccommodations, getAllCites};
};
