import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import SvgComp, {Dialog, DialogOverlay} from 'styles/common';
import {X} from 'assests/icons';
import {CustomButton} from '../Button/CustomButton';
import MobileDrag from './MobileDrag';

const DialogContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${({padding}) => padding ?? '1rem'};
`;

const FormWrapper = styled.div`
  p {
    font-size: 1.15rem;
    font-weight: 1rem;
  }
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  left: 0;
  right: 0;
  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${({theme}) => theme.bg[400]};
`;

const Title = styled.h5`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${({theme}) => theme.clr[200]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 3rem);
  padding: 1rem 2rem;
`;

const CloseDialog = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999rem;
  width: 2.5rem;
  height: 2.5rem;
  margin-inline-end: 2rem;
  background-color: ${({theme}) => theme.bg[200]};

  &:hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.bg[200]};
  }
`;

const CustomButtonWrapper = styled.div`
  border-top: 2px solid ${({theme}) => theme.bg[400]};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem;
`;

const FormSubmitDialog = ({
  setOpenDialog,
  openDialog,
  hideClose = false,
  isLoading,
  btnTxt,
  padding,
  dialogBoxWidth,
  title,
  sx,
  btnDisabled,
  handleSubmit,
  children
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);

  // Add ESC key handler
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && openDialog && !hideClose) {
        setOpenDialog(false);
      }
    };

    // Add event listener when dialog is open
    if (openDialog) {
      document.addEventListener('keydown', handleEscKey);
    }

    // Cleanup event listener when component unmounts or dialog closes
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [openDialog, hideClose, setOpenDialog]);

  // Add resize handler for mobile detection
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!openDialog) return null;
  if (isMobile) {
    return (
      <MobileDrag
        isMobile={isMobile}
        setIsMobile={setIsMobile}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        padding={padding}
        handleSubmit={handleSubmit}
        children={children}
      />
    );
  }

  return (
    <DialogOverlay>
      <Dialog maxWidth={dialogBoxWidth ?? '72rem'} sx={sx}>
        <TitleWrapper>
          <Title>{title ?? ''}</Title>
          {!hideClose && (
            <CloseDialog onClick={() => setOpenDialog(false)}>
              <SvgComp
                Icon={<X />}
                size='1.4rem'
                stroke={1}
                fillColor={'transparent'}
                strokeWidth={2}
              />
            </CloseDialog>
          )}
        </TitleWrapper>
        <DialogContent padding={padding}>
          <FormWrapper>{children}</FormWrapper>
        </DialogContent>

        <CustomButtonWrapper>
          <CustomButton
            onClick={handleSubmit}
            text={btnTxt ?? 'Save'}
            loadingIcon={isLoading}
            disabled={btnDisabled}
            sx={{
              padding: '0.75rem 2rem'
            }}
          />
        </CustomButtonWrapper>
      </Dialog>
    </DialogOverlay>
  );
};

export default FormSubmitDialog;
