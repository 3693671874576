import {useChatContext, useChatActionContext} from 'apps/chat/ChatProvider';

export const useChat = () => {
  const {
    messages,
    userTyping,
    isConnected,
    chatWorker,
    chatUser,
    loadingChat,
    conversation,
    prevMessages,
    privateKey,
    publicKey
  } = useChatContext();
  return {
    messages,
    userTyping,
    isConnected,
    chatWorker,
    chatUser,
    loadingChat,
    conversation,
    prevMessages,
    privateKey,
    publicKey
  };
};

export const useChatMethod = () => {
  const {
    sentEmail,
    joinCsChat,
    searchChat,
    sendMessage,
    notifyTyping,
    setPrivateKey,
    setPublicKey,
    searchWorker,
    setConversation,
    searchChatUsers,
    createPrivateChat,
    sendPrivateMessage,
    connectCSWebSocket,
    getUserPrivateRooms,
    connectPrivateWebSocket,
    getMessagesForChatRoom,
    createCustomerSupportChat,
    getAllCustomerServiceChats,
    getUserCustomerServiceRooms
  } = useChatActionContext();
  return {
    sentEmail,
    joinCsChat,
    searchChat,
    sendMessage,
    setPrivateKey,
    setPublicKey,
    searchWorker,
    notifyTyping,
    searchChatUsers,
    setConversation,
    createPrivateChat,
    sendPrivateMessage,
    connectCSWebSocket,
    getUserPrivateRooms,
    connectPrivateWebSocket,
    getMessagesForChatRoom,
    createCustomerSupportChat,
    getAllCustomerServiceChats,
    getUserCustomerServiceRooms
  };
};
