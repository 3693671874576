import {
  useThirdpartyAPICxt,
  useThirdpartyAPIActions
} from 'apps/thirdparty/ThirdpartyAPIProvider';

export const useThirdpartyAPI = () => {
  const {isLoading, geoApiAddress, formattedAddress, suggestingAddresses} =
    useThirdpartyAPICxt();
  return {isLoading, geoApiAddress, formattedAddress, suggestingAddresses};
};

export const useThirdpartyAPIMethod = () => {
  const {
    locateMe,
    setGeoApiAddress,
    setFormattedAddress,
    findAddress,
    fetchImages,
    findImages
  } = useThirdpartyAPIActions();
  return {
    findImages,
    locateMe,
    findAddress,
    setGeoApiAddress,
    setFormattedAddress,
    fetchImages
  };
};
