import {isEmptyObject} from '@myservice/helper';
import extractError from '@myservice/helper/extractError';
import {useNotificationMethod} from '@myservice/hooks';
import axiosInstance from 'apps/apis';
import {
  WORKER_CEILING,
  PAINTER_PACKAGE,
  WORKER_STONE,
  WORKER_OFFERING_DISHS,
  TOUR_GUIDE_PACKAGE,
  ROOMS,
  HOTEL_IMG,
  PACKAGES
} from 'apps/apis/allApis';
import React, {createContext, useContext, useState} from 'react';

const PackageContext = createContext();
const PackageContextAction = createContext();

export const PackageProvider = ({children}) => {
  const [packages, setPackages] = useState({
    packLoading: false,
    painterPackages: [],
    stoneTypePackages: [],
    chefPackages: [],
    ceilingTypePackages: [],
    tourGuidePackages: [],
    hotelRooms: [],
    allRooms: [],
    tourGuidePackage: null,
    painterPackage: null,
    chefPackage: null,
    countAllRooms: 0,
    nextAllRooms: null,
    prevAllRooms: null
  });

  const [bookedDates, setBookedDates] = useState({});
  const [roomReviews, setRoomReviews] = useState();
  const [packageReviews, setPackageReviews] = useState([]);

  const [rooms, setRooms] = useState({
    allRooms: [],
    next: null,
    prev: null,
    currentPage: 1,
    count: 0
  });

  const {showNotification} = useNotificationMethod();

  const fetchData = async (url, method = 'GET', payload = null, isFormData) => {
    try {
      setPackages((prev) => ({
        ...prev,
        packLoading: true
      }));
      const response = await axiosInstance({
        method,
        url,
        data: payload,
        headers: isFormData ? {'Content-Type': 'multipart/form-data'} : {}
      });

      setPackages((prev) => ({
        ...prev,
        packLoading: false
      }));
      return response.data;
    } catch (error) {
      setPackages((prev) => ({
        ...prev,
        packLoading: false
      }));
      showNotification(extractError(error.response.data));
      console.error(error);
      return error;
    }
  };

  const postPainterPackage = async (payload) => {
    const response = await fetchData(PAINTER_PACKAGE, 'POST', payload);
    if (response?.id) {
      showNotification('New package Added Succesfully', 'success');
      getPainterPackage(payload.worker);
    }
    return response;
  };

  const patchPainterPackage = async (payload) => {
    const response = await fetchData(
      `${PAINTER_PACKAGE}${payload.id}/`,
      'PATCH',
      payload
    );

    if (response?.id) {
      showNotification('Package Updated Succesfully', 'success');
      getPainterPackage(payload.worker);
    }
    return response;
  };

  const getPainterPackage = async (uuid) => {
    const response = await fetchData(`${PAINTER_PACKAGE}?worker=${uuid}`);
    setPackages((prev) => ({
      ...prev,
      painterPackages: response
    }));
    return response;
  };

  const retrievePainterPackage = async (uuid) => {
    const response = await fetchData(`${PAINTER_PACKAGE}${uuid}`);
    setPackages((prev) => ({
      ...prev,
      painterPackage: response
    }));
    return response;
  };

  const delPainterPackage = async (payload) => {
    const response = await fetchData(
      `${PAINTER_PACKAGE}${payload.id}/`,
      'DELETE'
    );
    getPainterPackage(payload.worker);
    return response;
  };

  const getStoneType = async (uuid) => {
    const response = await fetchData(`${WORKER_STONE}?installer=${uuid}`);
    setPackages((prev) => ({
      ...prev,
      stoneTypePackages: response
    }));
    return response;
  };

  const postStoneType = async (payload) => {
    const response = await fetchData(WORKER_STONE, 'POST', payload);
    getStoneType(payload.installer);
    return response;
  };

  const patchStoneType = async (payload) => {
    const response = await fetchData(
      `${WORKER_STONE}${payload.id}/`,
      'PATCH',
      payload
    );
    getStoneType(payload.installer);
    return response;
  };

  const delStoneType = async (payload) => {
    const response = await fetchData(`${WORKER_STONE}${payload.id}/`, 'DELETE');
    getStoneType(payload.installer);
    return response;
  };

  const postChefOffer = async (payload) => {
    const resp = await fetchData(WORKER_OFFERING_DISHS, 'POST', payload);
    getChefOffer(payload.chef);
    return resp;
  };

  const patchChefOffer = async (payload) => {
    const response = await fetchData(
      `${WORKER_OFFERING_DISHS}${payload.id}/`,
      'PATCH',
      payload
    );
    getChefOffer(payload.id);
    return response;
  };

  const getChefOffer = async (uuid) => {
    const response = await fetchData(`${WORKER_OFFERING_DISHS}?chef=${uuid}`);
    setPackages((prev) => ({
      ...prev,
      chefPackages: response
    }));
    return response;
  };

  const retrieveChefOffer = async (uuid) => {
    const response = await fetchData(`${WORKER_OFFERING_DISHS}${uuid}`);
    setPackages((prev) => ({
      ...prev,
      chefPackage: response
    }));
    return response;
  };

  const delChefOffer = async (payload) => {
    const response = await fetchData(
      `${WORKER_OFFERING_DISHS}${payload.id}/`,
      'DELETE'
    );
    getChefOffer(payload.chef);
    return response;
  };

  const postNewRoom = async (payload) => {
    return await fetchData(ROOMS, 'POST', payload, true);
  };

  const editRoomDetails = async (payload, UUID) => {
    return await fetchData(`${ROOMS}${UUID}/`, 'PATCH', payload, true);
  };

  const delRoomImage = async (ROOM_UUID, IMG_UUID) => {
    try {
      const response = await axiosInstance.delete(
        `${ROOMS}${ROOM_UUID}/delete-image/${IMG_UUID}`
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const getHotelRooms = async (UUID) => {
    const rooms = await fetchData(`${ROOMS}rooms/${UUID}`);
    setPackages((prev) => ({
      ...prev,
      hotelRooms: rooms
    }));
    return rooms;
  };

  const delHotelImage = async (ROOM_UUID, IMG_UUID) => {
    try {
      const response = await axiosInstance.delete(`${HOTEL_IMG}${IMG_UUID}/`);
      return response.data;
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const updateHotelImage = async (RESORT_UUID, payload) => {
    return await fetchData(`${HOTEL_IMG}${RESORT_UUID}/`, 'PUT', payload, true);
  };

  const getRoomReviews = async (UUID) => {
    const roomReview = await fetchData(`${ROOMS}${UUID}/reviews/`);
    setRoomReviews(roomReview);
    return roomReview;
  };

  const getPackageReviews = async (UUID) => {
    const roomReview = await fetchData(`${PACKAGES}${UUID}/reviews/`);
    setPackageReviews(roomReview);
    return roomReview;
  };

  const getAllRooms = async (requestedPage = null, searchParams = {}) => {
    const page =
      requestedPage || (rooms.next ? rooms.currentPage + 1 : rooms.currentPage);

    const params = {
      page_size: 100,
      ...(searchParams.destination && {destination: searchParams.destination}),
      ...(searchParams.dateRange?.start && {
        check_in: searchParams.dateRange.start
      }),
      ...(searchParams.dateRange?.end && {
        check_out: searchParams.dateRange.end
      }),
      ...(searchParams.guests?.adults && {adults: searchParams.guests.adults}),
      ...(searchParams.guests?.children && {
        children: searchParams.guests.children
      })
    };

    const config =
      rooms.next && !requestedPage && isEmptyObject(searchParams)
        ? {url: rooms.next}
        : {url: ROOMS, params};

    console.log(config);
    const {data} = await axiosInstance.request(config);

    console.log(data);

    if (!data) return null;

    setRooms((prev) => ({
      ...prev,
      allRooms:
        page > 1 && isEmptyObject(searchParams)
          ? [
              ...prev.allRooms,
              ...(data.results || []).filter(
                (newRoom) =>
                  !prev.allRooms.some((extRoom) => extRoom.id === newRoom.id)
              )
            ]
          : data.results || [],
      previous: data.previous,
      next: data.next,
      count: data.count || 0,
      currentPage: page
    }));

    return data;
  };

  const getRoomDetails = async (UUID) => {
    return await fetchData(`${ROOMS}${UUID}`);
  };

  const getRoomBookedDates = async (UUID) => {
    const bookedDates = await fetchData(`${ROOMS}${UUID}/future-booking-dates`);
    setBookedDates(bookedDates);
    return bookedDates;
  };

  const postCeilingType = async (payload) => {
    return await fetchData(WORKER_CEILING, 'POST', payload, true);
  };

  const patchCeilingType = async (payload) => {
    const response = await fetchData(
      `${WORKER_CEILING}${payload.id}/`,
      'PATCH',
      payload
    );
    getCeilingType(payload.installer);
    return response;
  };

  const getCeilingType = async (uuid) => {
    const response = await fetchData(`${WORKER_CEILING}?installer=${uuid}`);
    setPackages((prev) => ({
      ...prev,
      ceilingTypePackages: response
    }));
    return response;
  };

  const delCeilingType = async (payload) => {
    const response = await fetchData(
      `${WORKER_CEILING}${payload.id}/`,
      'DELETE'
    );
    getCeilingType(payload.installer);
    return response;
  };

  const postTourGuidePackage = async (payload) => {
    const response = await fetchData(TOUR_GUIDE_PACKAGE, 'POST', payload);

    if (response?.id) {
      getTourGuidePackage(payload['worker']);
      return response;
    }
  };

  const patchTourGuidePackage = async (payload) => {
    const response = await fetchData(
      `${TOUR_GUIDE_PACKAGE}${payload.id}/`,
      'PATCH',
      payload
    );
    // getTourGuidePackage(payload.guide_name);
    return response;
  };

  const getTourGuidePackage = async (uuid) => {
    const response = await fetchData(`${TOUR_GUIDE_PACKAGE}?worker=${uuid}`);
    setPackages((prev) => ({
      ...prev,
      tourGuidePackages: response
    }));
    return response;
  };

  const retrieveTourGuidePackage = async (uuid) => {
    const response = await fetchData(`${TOUR_GUIDE_PACKAGE}${uuid}`);
    setPackages((prev) => ({
      ...prev,
      tourGuidePackage: response
    }));
    return response;
  };

  const delTourGuidePackage = async (payload) => {
    const response = await fetchData(
      `${TOUR_GUIDE_PACKAGE}${payload.id}/`,
      'DELETE'
    );
    getTourGuidePackage(payload.guide_name);
    return response;
  };

  return (
    <PackageContext.Provider
      value={{...packages, ...rooms, bookedDates, roomReviews, packageReviews}}
    >
      <PackageContextAction.Provider
        value={{
          getCeilingType,
          postCeilingType,
          patchCeilingType,
          delCeilingType,

          getStoneType,
          postStoneType,
          patchStoneType,
          delStoneType,

          postPainterPackage,
          patchPainterPackage,
          getPainterPackage,
          delPainterPackage,
          retrievePainterPackage,

          getChefOffer,
          retrieveChefOffer,
          postChefOffer,
          patchChefOffer,
          delChefOffer,

          postTourGuidePackage,
          patchTourGuidePackage,
          delTourGuidePackage,
          getTourGuidePackage,
          retrieveTourGuidePackage,

          postNewRoom,
          getHotelRooms,
          getRoomDetails,
          getRoomBookedDates,
          getAllRooms,
          editRoomDetails,
          delRoomImage,
          delHotelImage,
          getRoomReviews,
          getPackageReviews,

          updateHotelImage
        }}
      >
        {children}
      </PackageContextAction.Provider>
    </PackageContext.Provider>
  );
};

export const usePackageContext = () => {
  return useContext(PackageContext);
};

export const usePackageContextAction = () => {
  return useContext(PackageContextAction);
};

export default PackageProvider;
