export const shortMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const shortdayName = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const yesOrNo = [
  {
    value: '',
    default: true,
    label: 'Choose An Option'
  },
  {
    value: false,
    label: 'No'
  },

  {
    value: true,
    label: 'Yes'
  }
];

export const badCouter = [
  {
    value: '',
    default: true,
    label: 'Choose An Option'
  },
  {
    value: '1 Single Bed',
    label: '1 Single Bed'
  }
];

export const couterNumber = [
  {
    value: '',
    default: true,
    label: 'Choose An Option'
  },
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 3,
    label: '3'
  },
  {
    value: 4,
    label: '4'
  },
  {
    value: 5,
    label: '5+'
  },
  {
    value: 10,
    label: '10+'
  },
  {
    value: 20,
    label: '20+'
  },
  {
    value: 30,
    label: '30+'
  },
  {
    value: 40,
    label: '40+'
  },
  {
    value: 50,
    label: '50+'
  },
  {
    value: 100,
    label: '100+'
  },
  {
    value: 250,
    label: '250+'
  }
];

export const propertyTypes = [
  {
    value: '',
    default: true,
    label: 'Choose An Option'
  },
  {
    value: 'hotel',
    label: 'Hotel'
  },
  {
    value: 'motel',
    label: 'Motel'
  },
  {
    value: 'hostel',
    label: 'Hostel'
  },
  {
    value: 'apartment',
    label: 'Apartment'
  },
  {
    value: 'villa',
    label: 'Villa'
  },
  {
    value: 'cabin',
    label: 'Cabin'
  },
  {
    value: 'bungalow',
    label: 'Bungalow'
  },
  {
    value: 'condo',
    label: 'Condo'
  },
  {
    value: 'townhouse',
    label: 'Townhouse'
  },
  {
    value: 'resort',
    label: 'Resort'
  },
  {
    value: 'farmhouse',
    label: 'Farmhouse'
  },
  {
    value: 'loft',
    label: 'Loft'
  },
  {
    value: 'studio',
    label: 'Studio'
  },
  {
    value: 'penthouse',
    label: 'Penthouse'
  },
  {
    value: 'guesthouse',
    label: 'Guesthouse'
  },
  {
    value: 'chalet',
    label: 'Chalet'
  },
  {
    value: 'cottage',
    label: 'Cottage'
  },
  {
    value: 'castle',
    label: 'Castle'
  },
  {
    value: 'manor',
    label: 'Manor'
  },
  {
    value: 'beachfront',
    label: 'Beachfront'
  },
  {
    value: 'farm',
    label: 'Farm'
  },
  {
    value: 'caravan',
    label: 'Caravan'
  },
  {
    value: 'treehouse',
    label: 'Treehouse'
  },
  {
    value: 'apartment_hotel',
    label: 'Apartment Hotel'
  },
  {
    value: 'boutique_hotel',
    label: 'Boutique Hotel'
  },
  {
    value: 'luxury_villa',
    label: 'Luxury Villa'
  },
  {
    value: 'skyscraper',
    label: 'Skyscraper'
  },
  {
    value: 'warehouse',
    label: 'Warehouse'
  },
  {
    value: 'shared_room',
    label: 'Shared Room'
  },
  {
    value: 'farm_stay',
    label: 'Farm Stay'
  },
  {
    value: 'glamping',
    label: 'Glamping'
  },
  {
    value: 'tiny_house',
    label: 'Tiny House'
  },
  {
    value: 'houseboat',
    label: 'Houseboat'
  },
  {
    value: 'palace',
    label: 'Palace'
  },
  {
    value: 'fort',
    label: 'Fort'
  },
  {
    value: 'manor_house',
    label: 'Manor House'
  },
  {
    value: 'château',
    label: 'Château'
  },
  {
    value: 'royal_residence',
    label: 'Royal Residence'
  },

  {
    value: 'private_island',
    label: 'Private Island'
  }
];

export const notAvailableForHiring = [
  'Travels & Tours Agency',
  'Hoteling & Resort Service'
];

export const securityFeatures = [
  '24/7 Security Personnel',
  'CCTV Surveillance Cameras',
  'Key Card Access Systems',
  'Smoke and Fire Detectors',
  'Emergency Exits with Lighting',
  'Safe Deposit Boxes in Rooms',
  'Electronic Door Locks',
  'Guestroom Security Screens',
  'Motion Sensor Lighting',
  'Visitor Access Control',
  'Alarm Systems',
  'Intercom Communication',
  'Secure Parking Facilities',
  'Security Audits and Training',
  'Cybersecurity Measures for Guest Data',
  'Metal Detectors at Entrances',
  'Biometric Authentication Systems',
  'Perimeter Fencing with Surveillance',
  'Restricted Back-of-House Access',
  'Room Service and Delivery Verification',
  'Child Safety Features in Rooms',
  'Fire Suppression Systems (Sprinklers)',
  'Security Patrols in Public Areas',
  'Panic Buttons in Guestrooms',
  'Secure Wi-Fi Networks for Guests',
  'Two-Factor Authentication for Online Booking',
  'Baggage Screening Systems',
  'Elevator Access Control',
  'Security Signage and Instructions for Guests',
  'On-Site First Aid and Emergency Response Teams',
  'Security-Verified Staff Identification',
  'Remote Locking/Unlocking Features',
  'Incident Reporting and Monitoring Systems',
  'Bulletproof Glass in High-Risk Areas',
  'Anti-Theft Systems for Hotel Amenities'
];

export const bathroomAmenities = [
  'Rain Shower Systems',
  'Handheld Showerheads',
  'Luxury Bathtubs',
  'Jacuzzi or Whirlpool Tubs',
  'Heated Towel Racks',
  'Anti-Fog Mirrors',
  'Magnifying Makeup Mirrors',
  'Hairdryers',
  'Bathroom Slippers',
  'Bathrobes',
  'High-Quality Towels',
  'Toiletries (Shampoo, Conditioner, Body Wash)',
  'Luxury Soap Bars',
  'Vanity Kits (Cotton Pads, Swabs, Nail Files)',
  'Toothbrush and Toothpaste Kits',
  'Shaving Kits (Razor and Shaving Cream)',
  'Bidets or Bidet Attachments',
  'Toilet Seat Sanitizers',
  'Adjustable Water Temperature Controls',
  'Motion-Activated Lighting',
  'Smart Bathroom Systems (Voice-Controlled Lighting or Temperature)',
  'Eco-Friendly Toiletries',
  'Recyclable Packaging for Toiletries',
  'Bathroom Scales',
  'Fragrant Room Diffusers or Candles',
  'Towel Warmers',
  'Non-Slip Mats or Flooring',
  'Bathroom Phone or Emergency Call Button',
  'Dedicated Shower Benches',
  'Underfloor Heating',
  'LED-Lit Mirrors',
  'Soap Dispensers with Sensor Technology',
  'Clotheslines or Drying Racks',
  'Toilet Paper Holder with Spare Rolls',
  'Bath Salts or Bubble Bath Accessories',
  'Waterproof Bluetooth Speakers',
  'Hooks or Hangers for Robes and Towels',
  'Personalized Bath Amenities (e.g., monogrammed items)',
  'Luxury Shower Curtains or Glass Panels',
  'Air Fresheners or Dehumidifiers',
  'Waste Bins with Liners'
];

export const hotelEnvironments = [
  'Urban Center',
  'Coastal Beachfront',
  'Mountain Retreat',
  'Rural Countryside',
  'Lakeside',
  'Historic Town',
  'Suburban Neighborhood',
  'Desert Oasis',
  'Ski Resort',
  'Tropical Island',
  'Safari Lodge',
  'Cultural District',
  'Vineyard',
  'Cruise Ship',
  'Business District'
];

export const jurisdictions = [
  'Supreme Court of Pakistan',
  'High Courts (Province-wise)',
  'District Courts',
  'Specialized Courts (Family Courts..)',
  'Sharia Courts',
  'Federal Shariat Court'
];

export const typesOfLawyers = [
  'Corporate Lawyer',
  'Criminal Lawyer',
  'Family Lawyer',
  'Personal Injury Lawyer',
  'Real Estate Lawyer',
  'Immigration Lawyer',
  'Intellectual Property Lawyer',
  'Environmental Lawyer',
  'Labor and Employment Lawyer',
  'Bankruptcy Lawyer',
  'Tax Lawyer',
  'Civil Rights Lawyer',
  'Healthcare Lawyer',
  'Entertainment Lawyer'
];

export const languages = [
  'French',
  'English',
  'Spanish',
  'Chinese',
  'Japanese',
  'Korean',
  'Italian',
  'German',
  'Arabic',
  'Urdu',
  'Farsi',
  'Pashto',
  'Punjabi',
  'Balti'
];

export const roomAmenities = [
  'Room Service',
  'Laundry Services',
  'Television',
  'Air Conditioning/Heating',
  'In-Room Kitchenette',
  'Luxury Bedding',
  'In-Room Entertainment Systems',
  'Mini Bar',
  'Safe Deposit Box',
  'Work Desk',
  'Coffee Maker',
  'Daily Housekeeping',
  'Soundproof Rooms',
  'Complimentary Wi-Fi',
  'Balcony/Terrace',
  'Electric Kettle',
  'Iron and Ironing Board',
  'Phone with Voicemail',
  'Blackout Curtains',
  'USB Charging Ports',
  'Dresser/Closet',
  'Pillow Menu',
  'Individually Controlled Heating/Cooling',
  'Smart Room Controls (Lighting, Curtains)',
  'Complimentary Bottled Water',
  'Welcome Basket or Snacks',
  'Sofa or Seating Area',
  'Full-Length Mirror',
  'Ambient Lighting Options',
  'In-Room Dining Table',
  'Smart TV with Streaming Apps',
  'Laptop-Friendly Workspace',
  'Wardrobe with Hangers',
  'Personalized Welcome Messages (Smart Systems)',
  'Do Not Disturb and Housekeeping Control Panels',
  'Pet-Friendly Accommodations (if applicable)'
];

export const accomationAmenities = [
  'Wi-Fi',
  'Breakfast',
  'Fitness Center',
  'Swimming Pool',
  'Business Center',
  'Parking',
  'Spa Services',
  'Pet-Friendly Services',
  'Concierge Services',
  'On-Site Restaurants',
  'Fitness Classes',
  'Event Spaces',
  'Airport Shuttle',
  'Eco-Friendly Features',
  'Rooftop Restaurants',
  'Private Pools/Hot Tubs',
  'Art Galleries/Exhibits',
  'Themed Rooms',
  'Personalized Services',
  'Outdoor Lounge Areas',
  'Game Rooms',
  'Live Entertainment',
  'Free Local Shuttle',
  'Wine Tasting Events',
  'Cooking Classes',
  'Photography Tours',
  'Cultural Experiences',
  'Workshops',
  '24-Hour Front Desk',
  'Gift Shop',
  'Meeting Rooms',
  'Library',
  'Children’s Play Area',
  'Business Services',
  'Ski Passes Available',
  'Shuttle Service',
  'Evening Entertainment',
  'Local Tours and Excursions'
];

export const travelingInclusions = [
  'Free Cancellation',
  'Airfare',
  'Train Ticket',
  'Bus Ticket',
  'Ferry Ticket',
  'Rental Cars',

  'Airport transfers',
  'Welcome at Airport',

  'Hotel or Lodging Reservations',
  'Room upgrades (Extra-charge apply)',
  'Extended stay arrangements (Extra-charge apply)',
  'Room upgrades (No Extra Charge)',
  'Extended stay arrangements (No Extra Charge)',

  'Travel Insurance',
  'Medical Emergencies Expenses',
  'Lost Luggage',

  'Only Breakfast',
  'Only Dinner',
  'Breakfast & Lunch',
  'Breakfast Lunch & Dinner',

  'Professional guide services',
  'Organized Group Tours',
  'Entrance fees to tourist sites',
  'Tickets for museums shows or events',
  'Souvenirs and shopping',
  'Refreshments and snacks',
  'Application fees for visas',
  'Tips for tour guides drivers or hotel staff'
];

export const meetingTimeing = [0.5, 1, 2, 3];
export const professionOffers = ['Hourly', 'Food', 'Ceiling', 'Stone'];

export const authHeaderLinks = [
  // {
  //   title: 'Chat',
  //   link: '/chat'
  // },
  {
    title: 'Accounts',
    link: '/account-settings/?tab=about'
  },
  {
    title: 'Orders',
    link: '/account-settings/?tab=orders'
  },
  {
    title: 'Hires',
    link: '/account-settings/?tab=hiring'
  },
  {
    title: 'Help Center',
    link: '/customer-support',
    below: true
  }
];
export const unAuthHeaderLinks = [
  {
    title: 'Sign Up',
    link: '/on-boarding',
    strong: true
  },

  {
    title: 'Login',
    link: '/on-boarding',
    below: true
  },

  {
    title: 'Help Center',
    link: '/customer-support',
    below: true
  }
];

export const typeOfBussinessForTravelTourAgency = [
  'International Package Tours',
  'Local Package Tours',
  'Hotel Bookings',
  'Flight Reservations',
  'Transportation Services',
  'Travel Insurance',
  'Visa Assistance',
  'Guided Tours',
  'Hajj/Umrah Service'
];

export const areaOfExpertiseAsTourGuide = [
  'Cultural Tours',
  'Historical Tours',
  'Adventure Tours',
  'Ecotours',
  'Wildlife Tours',
  'Food Tours',
  'Art Tours',
  'Architecture Tours',
  'Photography Tours',
  'Cruise Tours',
  'Walking Tours',
  'Bicycle Tours',
  'Segway Tours',
  'Ghost Tours',
  'Festival Tours',
  'Shopping Tours',
  'Volunteer Tours',
  'Luxury Tours',
  'Educational Tours'
];

export const areaOfExpertiseQuranTeacher = [
  'Quranic recitation (Tajweed)',
  'Quranic memorization (Hifz)',
  'Quranic interpretation (Tafsir)',
  'Arabic language',
  'Teaching methodologies'
];

export const surfaceType = [
  {
    value: '',
    label: 'Please Select A surface type',
    default: true
  },

  {
    value: 'entire_building_interior_exterior',
    label: 'Entire building Interior and Exterior'
  },

  {value: 'interior_wall_only', label: 'Interior Wall Only'},
  {value: 'interior_wall_ceiling', label: 'Interior Wall & Ceiling'},

  {
    value: 'interior_wall_ceiling_door_trims',
    label: 'Interior Wall Ceilings Door Window and Trims'
  },

  {value: 'doors_windows_trims', label: 'Doors Windows and Trim'},
  {value: 'cabinet_only', label: 'Cabinets Only'},

  {value: 'exterior_wall', label: 'Exterior Wall Only'},
  {value: 'exterior_wall_railings', label: 'Exterior Wall & Railing'},

  {
    value: 'entire_building',
    label: 'Offer a Painting bit for ther entire builiding'
  },
  {value: 'other', label: 'Other (please specify)'}
];

export const bitOfferUnits = [
  {
    value: '',
    label: 'Choose Unit',
    default: true
  },
  {
    value: 'marla',
    label: 'Marla'
  },
  {
    value: 'kanel',
    label: 'Kanal'
  },

  {
    value: 'square_meter',
    label: 'Square meter'
  },
  {
    value: 'square_ft',
    label: 'Square feet'
  },
  {
    value: 'square_km',
    label: 'Square kilometer'
  },

  {
    value: 'acre',
    label: 'Acre'
  }
];

export const chooseUnit = [
  {
    value: 'm²',
    label: 'm²'
  },
  {
    value: 'ft²',
    label: 'ft²'
  }
];

export const surfaceMaterial = [
  {value: 'cement', label: 'Cement Surface'},
  {
    value: 'drywall',
    label: 'Drywall or Plaster (gypsum, paper etc)'
  },
  {value: 'wooden', label: 'Wooden Surface'},
  {value: 'brick_rock', label: 'Brick Rock Surface'},
  {
    value: 'alloy',
    label: 'Alloy (Iron, Aluminum, Copper etc.)'
  },
  {value: 'other', label: 'Other (please specify)'}
];

export const surfaceCondition = [
  {
    value: '',
    label: 'Choose An Option',
    default: true
  },
  {value: 'new_surface', label: 'New Surface'},
  {value: 'old_surface', label: 'Weathered/Old Surface'},
  {value: 'damaged_surface', label: 'Damaged Surface'}
];

export const chefSpecialties = [
  {
    value: 'regional_cuisine',
    label: 'Regional Cuisine',
    description:
      'Mastery of dishes from a specific region or country, such as Indian, Italian, French, Thai etc, or Mexican cuisine.'
  },
  {
    value: 'seafood',
    label: 'Seafood',
    description:
      'Expertise in preparing various seafood dishes, from simple grilled fish to elaborate seafood platters.'
  },
  {
    value: 'grilling_barbecue',
    label: 'Grilling and Barbecue',
    description:
      'Skill in grilling and smoking meats and vegetables, including techniques like slow-cooking and using different marinades and rubs.'
  },
  {
    value: 'pastry_desserts',
    label: 'Pastry and Desserts',
    description:
      'Talent in creating intricate and delicious desserts, pastries, cakes, and confections.'
  },
  {
    value: 'farm_to_table',
    label: 'Farm-to-Table',
    description:
      'Emphasis on using fresh, locally sourced ingredients in creative and sustainable ways.'
  },
  {
    value: 'vegetarian_vegan',
    label: 'Vegetarian or Vegan Cuisine',
    description:
      'Specializing in plant-based cooking, creating flavorful and innovative dishes without meat or animal products.'
  },
  {
    value: 'fusion_cuisine',
    label: 'Fusion Cuisine',
    description:
      'Combining elements from different culinary traditions to create unique and eclectic dishes.'
  },
  {
    value: 'butchery',
    label: 'Butchery',
    description:
      'Skill in butchering meats, including selecting cuts, aging, and preparing meat dishes from scratch.'
  },

  {
    value: 'artisanal_bread_baking',
    label: 'Artisanal Bread and Baking',
    description:
      'Expertise in crafting bread, pastries, and baked goods using traditional methods and high-quality ingredients.'
  },
  {
    value: 'fine_dining',
    label: 'Fine Dining',
    description:
      'Creating refined and elegant dishes suitable for high-end dining experiences, often with meticulous attention to detail in presentation and flavor.'
  },
  {
    value: 'private_dining_catering',
    label: 'Private Dining or Catering',
    description:
      'Experience in designing menus and preparing meals for private events, weddings, and special occasions.'
  }
];

export const foodExpertise = [
  'Regional Cuisine',
  'Seafood',
  'Grilling and Barbecue',
  'Pastry and Desserts',
  'Farm-to-Table',
  'Vegetarian or Vegan Cuisine',
  'Fusion Cuisine',
  'Butchery',
  'Artisanal Bread and Baking',
  'Fine Dining',
  'Private Dining or Catering'
];

export const homeType = [
  'Single Story Home ',
  'Double Story Home',
  'Three Story Home',
  'Apartment Building',
  'Commercial Building',
  'Industrial Building',
  'Office Building',
  'Retail Store',
  'Warehouse',
  'Hotel',
  'Hospital',
  'School',
  'Mosque',
  'Restaurant',
  'Shopping Mall',
  'Cinema/Theater',
  'Gym/Fitness Center'
];

export const packageType = [
  {
    value: '',
    label: '',
    default: true
  },
  {value: 'ADVENTURE', label: 'Adventure'},
  {value: 'CULTURAL', label: 'Cultural'},
  {value: 'RELAXATION', label: 'Relaxation'},
  {value: 'HISTORICAL', label: 'Historical'},
  {value: 'NATURE', label: 'Nature'},
  {value: 'LUXURY', label: 'Luxury'},
  {value: 'ECOTOURISM', label: 'Ecotourism'},
  {value: 'SPORTS', label: 'Sports'},
  {value: 'FAMILY', label: 'Family'},
  {value: 'ROMANTIC', label: 'Romantic'},
  {value: 'FOOD_DRINK', label: 'Food & Drink'},
  {value: 'ADVENTURE_SPORTS', label: 'Adventure Sports'},
  {value: 'CRUISE', label: 'Cruise'},
  {value: 'FESTIVALS_EVENTS', label: 'Festivals & Events'},
  {value: 'EDUCATIONAL', label: 'Educational'},
  {value: 'WELLNESS', label: 'Wellness'},
  {value: 'PHOTOGRAPHY', label: 'Photography'},
  {value: 'LOCAL_EXPERIENCES', label: 'Local Experiences'},
  {value: 'BACKPACKING', label: 'Backpacking'},
  {value: 'GROUP_TOURS', label: 'Group Tours'},
  {value: 'PILGRIMAGE', label: 'Pilgrimage'},
  {value: 'HONEYMOON', label: 'Honeymoon'},
  {value: 'HIKING', label: 'Hiking'},
  {value: 'CLIMBING', label: 'Climbing'},
  {value: 'EIGHT_THOUSANDER_CLUMB', label: 'Eight Thousander Club'}
];

export const chargeBaseOn = [
  {label: 'Upto Two People', value: 'upto 6'},
  {label: 'Upto Six People', value: 'upto 6'},
  {label: 'Upto Eight People', value: 'upto 8'},
  {label: 'Upto Ten People', value: 'upto 10'},
  {label: 'Upto Twelve People', value: 'upto 12'},
  {label: 'Upto Eighteen People', value: 'upto 18'},
  {label: 'Upto Twenty Four People', value: 'upto 24'},
  {label: 'Upto Thirty Six People', value: 'upto 36'},
  {label: 'Upto Fifty People', value: 'upto 50'},
  {label: 'Square Foot', value: 'ft²'},
  {label: 'Square Meter', value: 'm²'},
  {label: 'Hour', value: 'hour'},
  {label: 'Lesson', value: 'lesson'},
  {label: 'Day', value: 'day'},
  {label: 'Project', value: 'project'},
  {label: 'Trip', value: 'trip'},
  {label: 'Person', value: 'person'},
  {label: 'Couple', value: 'couple'},
  {label: 'Night', value: 'night'}
];

export const buildingType = [
  {
    value: '',
    label: 'Choose An Option',
    default: true
  },
  {value: 'single_story_home', label: 'Single Story Home'},
  {value: 'double_story_home', label: 'Double Story Home'},
  {value: 'three_story_home', label: 'Three Story Home'},
  {value: 'apartment_building', label: 'Apartment Building'},
  {value: 'commercial_building', label: 'Commercial Building'},
  {value: 'industrial_building', label: 'Industrial Building'},
  {value: 'office_building', label: 'Office Building'},
  {value: 'retail_store', label: 'Retail Store'},
  {value: 'warehouse', label: 'Warehouse'},
  {value: 'hotel', label: 'Hotel'},
  {value: 'hospital', label: 'Hospital'},
  {value: 'school', label: 'School'},
  {value: 'mosque', label: 'Mosque'},
  {value: 'restaurant', label: 'Restaurant'},
  {value: 'mall', label: 'Shopping Mall'},
  {value: 'cinema', label: 'Cinema/Theater'},
  {value: 'gym', label: 'Gym/Fitness Center'},
  {value: 'parking', label: 'Parking Garage'},
  {value: 'other', label: 'Other'}
];

export const paintCondition = [
  {
    value: '',
    label: 'Please Choose an option',
    default: true
  },
  {value: 'no_paint', label: 'No paint previouly'},
  {value: 'painted_but_want_to_change', label: 'Painted but want to change'}
];

export const exteriorWall = [
  {
    value: '',
    label: 'Please Choose a Type',
    default: true
  },
  {value: 'exterior_wall_paint', label: 'Exterior Wall Paint'},
  {value: 'rock_wall', label: 'Rock Wall'}
];

export const doorPaint = [
  {
    value: '',
    label: 'Please Choose a Type',
    default: true
  },
  {value: 'polish', label: 'Polish'},
  {value: 'paint', label: 'Paint'}
];

export const painting_specialize_in = [
  {
    value: '',
    label: 'Please Choose a Type',
    default: true
  },
  {value: 'painting_contractor', label: 'Painting Contractor'},
  {value: 'painting', label: 'Painting'},
  {value: 'polishing', label: 'Polishing'}
];

export const stone_specialize_in = [
  {
    value: '',
    label: 'Please Choose a Type',
    default: true
  },
  {value: 'contractor', label: 'Contractor'},
  {value: 'worker', label: 'Worker'}
];

export const offer_lb_mater = [
  {
    value: '',
    label: 'Please Choose a Type',
    default: true
  },
  {value: false, label: 'Labor only'},
  {value: true, label: 'Labour and Material both'}
];

export const offerOnlineClass = [
  {
    value: '',
    label: 'Please Choose a Type',
    default: true
  },
  {value: true, label: 'I offer online class'},
  {value: false, label: "I don't offer online class"}
];

export const materialIncluded = [
  {
    value: '',
    label: 'Please Choose a Type',
    default: true
  },
  {value: 'labor_only', label: 'Labor Only'},
  {value: 'material_labor', label: 'Material and Labor'}
];

export const chargeBase = [
  {
    value: '',
    label: 'Please Choose a Unit',
    default: true
  },
  {
    value: 'hr',
    label: 'Hourly'
  },
  {
    value: 'lesson',
    label: 'Lesson'
  },
  {
    value: 'daily',
    label: 'Dialy'
  },
  {
    value: 'monthly',
    label: 'Monthly'
  },

  {
    value: 'm²',
    label: 'Sq Meters (m²)'
  },
  {
    value: 'ft²',
    label: 'Sq Feet (ft²)'
  },

  {
    value: 'project',
    label: 'Project-based'
  }
];

export const addressType = [
  {
    value: '',
    label: 'Please Choose a Type',
    default: true
  },
  {value: 'home', label: 'House'},
  {value: 'apartment', label: 'Apartment'},
  {value: 'office', label: 'Office'},
  {value: 'warehouse', label: 'Ware House'},
  {value: 'shop', label: 'Shop'},
  {value: 'none', label: 'None'}
];

export const painting_comp = [
  {
    value: '',
    label: 'Please Choose a Paint Type',
    default: true
  },
  {value: 'ici_dulux', label: 'ICI Dulux'},
  {value: 'nippon', label: 'Nippon Paint'},
  {value: 'brighto', label: 'Brighto Paint'},
  {value: 'master', label: 'Master Paint'},
  {value: 'berger', label: 'Berger Paint'},
  {value: 'gobis', label: 'Gobis Paint'},
  {value: 'blue_bird', label: 'Blue Bird'},
  {value: 'kansai', label: 'Kansai Paints'},
  {value: 'akzonobel', label: 'AkzoNobel Paint'},
  {value: 'sparco', label: 'Sparco Paint'},
  {value: 'alwan', label: 'Alwan Paint'},
  {value: 'jotun', label: 'Jotun Paint'}
];

export const specificDish = [
  {
    value: 'sushi',
    label: 'Sushi',
    description:
      'A Japanese dish consisting of vinegared rice topped with various ingredients such as seafood, vegetables, and occasionally tropical fruits.'
  },
  {
    value: 'pizza',
    label: 'Pizza',
    description:
      'An Italian dish consisting of a round, flat base of dough topped with tomato sauce, cheese, and various toppings like meats, vegetables, and herbs.'
  },
  {
    value: 'tacos',
    label: 'Tacos',
    description:
      'A Mexican dish consisting of a folded or rolled tortilla filled with various ingredients such as meats, beans, cheese, and vegetables.'
  }
];

export const rooms = [
  {
    value: 'livingRoom',
    label: 'Living Room',
    description: 'Number of Living Rooms'
  },
  {
    value: 'bedroom',
    label: 'Bedroom',
    description: 'Number of Bedrooms'
  },
  {
    value: 'bathroom',
    label: 'Bathroom',
    description: 'Number of Bathrooms'
  },
  {
    value: 'diningRoom',
    label: 'Dining Room',
    description: 'Number of Dining Rooms'
  },
  {
    value: 'office',
    label: 'Office',
    description: 'Number of Offices'
  },
  {
    value: 'hallway',
    label: 'Hallway',
    description: 'Number of Hallways'
  },
  {
    value: 'entryway',
    label: 'Entryway',
    description: 'Number of Entryways'
  },
  {
    value: 'laundryRoom',
    label: 'Laundry Room',
    description: 'Number of Laundry Rooms'
  },
  {
    value: 'garage',
    label: 'Garage',
    description: 'Number of Garages'
  },
  {
    value: 'attic',
    label: 'Attic',
    description: 'Number of Attics'
  },
  {
    value: 'pantry',
    label: 'Pantry',
    description: 'Number of Pantries'
  },
  {
    value: 'guestRoom',
    label: 'Guest Room',
    description: 'Number of Guest Rooms'
  },
  {
    value: 'playroom',
    label: 'Playroom',
    description: 'Number of Playrooms'
  },
  {
    value: 'kitchen',
    label: 'Kitchen',
    description: 'Number of Kitchens'
  },
  {
    value: 'other',
    label: 'Other',
    description: 'Other place not mentioned'
  }
];

export const kitchenEssentials = [
  'Chef’s Knife',
  'Paring Knife',
  'Serrated Knife',
  'Cutting Board',
  'Measuring Cups',
  'Measuring Spoons',
  'Mixing Bowls',
  'Wooden Spoon',
  'Whisk',
  'Peeler',
  'Can Opener',
  'Tongs',
  'Ladle',
  'Spatula',
  'Colander',
  'Grater',
  'Zester',
  'Garlic Press',
  'Mortar and Pestle',
  'Tweezers',
  'Oven Mitts',
  'Fish Spatula',
  'Pasta Fork',
  'Pie Server',
  'Butter Knife',
  'Cookie Cutters',
  'Can Strainer',
  'Kitchen Shears',
  'Egg Slicer',
  'Dough Scraper',
  'Splatter Guard',
  'Skillet/Frying Pan',
  'Saucepan',
  'Stockpot',
  'Dutch Oven',
  'Roasting Pan',
  'Baking Sheet',
  'Cake Pan',
  'Muffin Tin',
  'Casserole Dish',
  'Nonstick Bakeware',
  'Grill Pan',
  'Crepe Pan',
  'Wok',
  'Griddle',
  'Double Boiler',
  'Steamer Basket',
  'Braiser Pan',
  'Copper Pan',
  'Pressure Cooker',
  'Baking Mat',
  'Blender',
  'Food Processor',
  'Stand Mixer',
  'Hand Mixer',
  'Toaster',
  'Microwave',
  'Coffee Maker',
  'Electric Kettle',
  'Rice Cooker',
  'Slow Cooker',
  'Immersion Blender',
  'Electric Griddle',
  'Air Fryer',
  'Induction Cooktop',
  'Bread Maker',
  'Juicer',
  'Food Dehydrator',
  'Milk Frother',
  'Hot Water Dispenser',
  'Lemon Juicer',
  'Corn Stripper',
  'Nutcracker',
  'Butter Dish',
  'Pizza Stone',
  'Tartar Sauce Spoon',
  'Citrus Zester',
  'Mincer',
  'Bowl Scraper',
  'Cheese Plane',
  'Food Storage Containers',
  'Spice Rack',
  'Knife Block',
  'Pot Holders',
  'Dish Rack',
  'Drawer Dividers',
  'Pantry Canisters',
  'Over-the-Sink Colander',
  'Under-Cabinet Basket',
  'Lazy Susan',
  'Pot Lid Holder',
  'Vertical Knife Holder',
  'Refrigerator Organizer Bins',
  'Pantry Shelf Riser',
  'Drawer Organizers for Utensils',
  'Magnetic Spice Rack',
  'Bread Box',
  'Dishcloths',
  'Sponges',
  'Scrub Brushes',
  'Dish Soap',
  'Kitchen Towels',
  'Kitchen Thermometer',
  'Fire Extinguisher',
  'First Aid Kit',
  'Kitchen Timer with Multiple Alarms',
  'Heat-Resistant Gloves',
  'Cut-Resistant Gloves',
  'Smoke Detector',
  'Kitchen First Aid Manual',
  'Non-Slip Mats',
  'Child Safety Locks for Cabinets',
  'Food Safety Chart',
  'Anti-Fatigue Mat',
  'Food Storage Labels',
  'Basting Brush',
  'Pizza Cutter',
  'Egg Separator',
  'Meat Tenderizer',
  'Sifter',
  'Rolling Pin',
  'Pastry Brush',
  'Ice Cream Scoop',
  'Dishwasher Safe Scrubbing Pads',
  'Garbage Disposal Brush',
  'Countertop Cleaner',
  'Glass Cleaner',
  'Recycling Bins',
  'Microfiber Cloths',
  'Stainless Steel Cleaner',
  'Kitchen Trash Can with Lid',
  'Dishwasher Tablets',
  'Squeegee',
  'Potato Masher',
  'Salad Spinner',
  'Melon Baller',
  'Cheese Grater',
  'Mandoline Slicer',
  'Egg Poacher',
  'Tongs with Lock',
  'Chopsticks',
  'Basting Brush with Silicone Bristles',
  'Gravy Separator',
  'Ice Cube Trays',
  'Salad Tongs',
  'Vegetable Steamer',
  'Cheesecloth',
  'Food Mill',
  'Nesting Bowls',
  'Doughnut Pan',
  'Creme Brulee Torch',
  'Garnishing Tools',
  'Pasta Machine',
  'Butter Warmer',
  'Oil Mister',
  'Cupcake Liners',
  'Sushi Mat',
  'Cookie Press',
  'Meat Thermometer',
  'Soup Ladle',
  'Pickle Picker',
  'Strainer',
  'Funnel',
  'Cake Tester',
  'Silicone Baking Mats',
  'Herb Scissors',
  'De-boning Knife',
  'Fry Basket',
  'Butter Paddle',
  'Pasta Spoon',
  'Bread Lame',
  'Timbale Molds',
  'Roasting Rack',
  'Dough Docker',
  'Ramekins',
  'Pie Weights',
  'Margarine Spreaders',
  'Potato Ricer',
  'Melon Slicer',
  'Egg Beater',
  'Electric Can Opener',
  'Cookbook Stand',
  'Salad Dressing Shaker',
  'Batter Dispenser',
  'Fish Scaler',
  'Pastry Cutter',
  'Food Processor Blade Set',
  'Frypan Lid',
  'Cake Leveler',
  'Fondue Pot',
  'Sifter with Handle',
  'Clam Knife',
  'Egg Poaching Cups',
  'Gravy Boat',
  'Oil Bottle',
  'Tea Infuser',
  'Pie Crust Shield',
  'Corn Cob Holders',
  'Salad Dressing Mixer',
  'Pasta Tongs',
  'Pizza Peel',
  'Cheese Slicer',
  'Egg Crate',
  'Food Storage Bags',
  'Cooking Thermometer',
  'Kitchen Scale',
  'Dough Bowl',
  'Waffle Maker',
  'Crepe Spreader',
  'Corn On The Cob Butterer',
  'Jelly Roll Pan',
  'Butter Warmer',
  'Dishpan',
  'Potpourri Maker',
  'Pasta Strainer',
  'Food Chopper',
  'Egg Cooking Ring',
  'Cookie Cutter Set',
  'Egg Poaching Pan',
  'Glass Baking Dish',
  'Microwave Popcorn Maker',
  'Cake Decorating Tools',
  'Pasta Measuring Tool',
  'Salad Tossing Forks',
  'Chocolate Tempering Tool',
  'Cheese Fondue Set',
  'Avocado Slicer',
  'Chocolate Melter',
  'Spaghetti Server',
  'Fruit Peeler',
  'Cake Pop Maker',
  'Heat Resistant Utensils',
  'Herb Stripper',
  'Tart Pan',
  'Olive Oil Dispenser',
  'Cake Pan Set',
  'Skewer Set',
  'Tortilla Press',
  'Frothing Pitcher',
  'Mini Pancake Maker',
  'Silicone Molds',
  'Biscuit Cutter',
  'Grill Brush',
  'Meat Injector',
  'Egg Whisk',
  'Coffee Grinder',
  'Can Strainer',
  'Potato Peeler',
  'Kebab Skewers',
  'Butter Melter',
  'Food Storage Canisters',
  'Dehydrator Sheets',
  'Milk Steamer',
  'Cooling Racks',
  'Cake Decorating Tips',
  'Ginger Grater',
  'Honey Dipper',
  'Pizza Cutter Wheel',
  'Grill Pan with Removable Handle',
  'Round Cake Pans',
  'Grill Pan Press',
  'Cookware Set',
  'Mini Food Processor',
  'Ice Cream Maker',
  'Tortilla Warmer',
  'Food Steamer',
  'Eggshell Separator',
  'Silicone Utensil Set',
  'Thermometer for Candy',
  'Bread Proofing Basket',
  'Butter Dish with Lid',
  'Pie Dish',
  'Microplane Zester',
  'Cooking Brush',
  'Oil Pump Dispenser',
  'Fruit Picker',
  'Roasting Pan with Rack',
  'Canning Tools',
  'Pasta Portioning Tool',
  'Silicone Spatula Set',
  'Pasta Drying Rack',
  'Ginger Grater',
  'Nut Milk Bag',
  'Nonstick Grill Mat',
  'Bowl Cover Set',
  'Bread Slicing Guide',
  'Wooden Rolling Pin',
  'Pizza Stone Set',
  'Pasta Spoons',
  'Cake Slicing Knife',
  'Avocado Tool',
  'Bag Clips',
  'Tongs with Silicone Tips',
  'Pie Server Set',
  'Heat Resistant Gloves',
  'Silicone Pot Holders',
  'Pastry Cutter Set',
  'French Fry Cutter',
  'Rice Paddle',
  'Mini Sifter',
  'Fruit Corer',
  'Cake Decorating Spatula',
  'Bamboo Steamer',
  'Pie Crust Cutter',
  'Cupcake Carrier',
  'Round Baking Pans',
  'Taco Holder',
  'Pasta Spoons Set',
  'Baguette Pan',
  'Pasta Fork Set',
  'Roasting Pan Set',
  'Frying Pan Set',
  'Cheese Board Set',
  'Pasta Maker Attachment',
  'Herb Grinder',
  'Doughnut Cutter',
  'Beef Tenderizer',
  'Fish Fillet Knife',
  'Garlic Peeler',
  'Pasta Bowl',
  'Griddle Pan',
  'Pie Crust Rolling Pin',
  'Butter Dish with Spreader',
  'Pasta Clamps',
  'Cheese Knife Set',
  'Herb Keeper',
  'Egg Poacher Set',
  'Jelly Strainer',
  'Pie Crust Rolling Mat',
  'Food Sealer Bags',
  'Cake Cooling Rack Set',
  'Cutting Board with Measurements',
  'Pie Weight Set',
  'Oven Thermometer',
  'Pan Protector',
  'Cookie Sheet',
  'Cupcake Liners Set',
  'Cake Decorator Set',
  'Skillet with Lid',
  'Braising Pan',
  'Waffle Iron',
  'Cake Pop Stand',
  'Cheese Fondue Forks',
  'Cheese Grater Set',
  'Mini Grater',
  'Pie Crust Lattice Cutter',
  'Steak Knife Set',
  'Tart Mold Set',
  'Pasta Portioning Tool',
  'Grill Basket',
  'Grill Mat',
  'Cake Tester Set',
  'Bread Box with Lid',
  'Mini Muffin Tin',
  'Pastry Blender',
  'Butter Knife Set',
  'Meat Thermometer with Probe',
  'Ladle with Measuring Markings',
  'Ice Cream Spade',
  'Cupcake Decorating Kit',
  'Cake Knife Set',
  'Butter Wrapper',
  'Fruit Peeler Set',
  'Chef’s Torch',
  'Cupcake Stand',
  'Food Grade Gloves',
  'Pizza Maker',
  'Stainless Steel Mixing Bowls',
  'Instant Read Thermometer',
  'Spiralizer',
  'Potato Ricer Set',
  'Doughnut Pan Set',
  'Pie Crust Tool Set',
  'Pasta Machine Set',
  'Cheese Knife Set',
  'Biscuit Cutter Set',
  'Pasta Spoon Set',
  'Fruit Knife Set',
  'Grill Cleaner',
  'Pan Handle Cover',
  'Grill Fork',
  'Cheese Slicer Set',
  'Cake Decorating Bag',
  'Cheese Fondue Pot',
  'Grill Thermometer',
  'Griddle Plate',
  'Cheese Knife',
  'Cake Board Set',
  'Fruit Picker Set',
  'Cooking Thermometer Set',
  'Vegetable Peeler Set',
  'Food Processor with Attachments',
  'Pizza Stone with Tray',
  'Multi-Function Tool Set',
  'Spice Grinder',
  'Fondue Set',
  'Bread Maker with Timer',
  'Silicone Brush Set',
  'Pie Crust Decorator',
  'Cake Decorating Kit',
  'Round Cake Decorating Set',
  'Bread Knife Set',
  'Herb Chopper',
  'Vegetable Cutter Set',
  'Margarine Knife',
  'Egg Separator Set',
  'Food Processor with Bowl',
  'Cake Decorating Supplies',
  'Cooking Spoon Set',
  'Food Mill Set',
  'Cheese Grater with Bowl',
  'Multi-Tool for Kitchen',
  'Baking Tool Set',
  'Cake Pan with Divider',
  'Chef’s Knife Set',
  'Cake Slicing Tool',
  'Food Processor with Grater',
  'Silicone Baking Pan Set',
  'Fruit Knife Set',
  'Cookware Organizer',
  'Grill Skewer Set',
  'Cake Frosting Spatula',
  'Silicone Utensil Holder',
  'Egg White Separator',
  'Meat Tenderizer Set',
  'Cooking Set with Utensils',
  'Grill Set with Tools',
  'Cookbook Holder',
  'Mini Skillet Set',
  'Butter Dish with Knife',
  'Cooking Tool Set',
  'Pastry Brush Set',
  'Cake Slicing Knife Set',
  'Cake Decorating Bags with Tips',
  'Cheese Board with Knives',
  'Grill Set with Accessories',
  'Food Storage Containers Set',
  'Vegetable Steamer Set',
  'Grill Thermometer Set',
  'Pasta Tools Set',
  'Cupcake Maker',
  'Cake Decorating Kit with Tips',
  'Cheese Grater with Container',
  'Egg Poaching Set with Rack',
  'Silicone Baking Tools',
  'Cake Pan with Lid',
  'Cooking Utensils Set',
  'Griddle Set',
  'Silicone Kitchen Mat',
  'Bread Making Kit',
  'Pasta Maker with Accessories',
  'Cake Cutter Set',
  'Kitchen Tool Set',
  'Food Storage Set',
  'Cheese Knife Set with Board',
  'Cake Decorating Tools Set',
  'Grill Set with Thermometer',
  'Pie Baking Tools',
  'Pastry Tool Set',
  'Bread Box with Tray',
  'Cookware Set with Utensils',
  'Cupcake Carrier Set',
  'Baking Tools Set',
  'Pizza Maker with Stone',
  'Silicone Baking Tools Set',
  'Food Processor with Attachments Set',
  'Cake Slicing Tools Set',
  'Grill Accessories Set',
  'Pie Crust Tool Set',
  'Cake Decorating Tools with Tips',
  'Cheese Board Set with Knives',
  'Cooking Thermometer Set',
  'Grill Set with Utensils',
  'Food Storage Set with Containers',
  'Baking Set with Tools',
  'Silicone Baking Pan Set',
  'Cake Decorating Set with Tools',
  'Pie Crust Tools Set'
];

export const basicIngredients = [
  'Chicken Breast',
  'Ground Beef',
  'Salmon Fillets',
  'Eggs',
  'Tofu',
  'Milk',
  'Cheddar Cheese',
  'Yogurt',
  'Butter',
  'Olive Oil',
  'Vegetable Oil',
  'Garlic',
  'Onions',
  'Bell Peppers',
  'Carrots',
  'Potatoes',
  'Spinach',
  'Tomatoes',
  'Broccoli',
  'Green Beans',
  'Mushrooms',
  'Zucchini',
  'Lettuce',
  'Rice',
  'Pasta',
  'Bread',
  'Quinoa',
  'Oats',
  'Flour',
  'Sugar',
  'Salt',
  'Black Pepper',
  'Cumin',
  'Paprika',
  'Chili Powder',
  'Oregano',
  'Basil',
  'Thyme',
  'Rosemary',
  'Soy Sauce',
  'Tomato Sauce',
  'Vinegar',
  'Mustard',
  'Honey',
  'Canned Beans',
  'Lentils',
  'Frozen Vegetables',
  'Coconut Milk',
  'Chicken Broth',
  'Beef Broth',
  'Lemon Juice',
  'Lime Juice',
  'Garlic Powder',
  'Onion Powder',
  'Cinnamon',
  'Nutmeg',
  'Vanilla Extract',
  'Baking Powder',
  'Baking Soda',
  'Egg Noodles',
  'Tortillas',
  'Canned Tomatoes',
  'Canned Corn',
  'Soy Milk',
  'Almond Milk',
  'Coconut Oil',
  'Sesame Oil',
  'Parmesan Cheese',
  'Cream Cheese',
  'Ricotta Cheese',
  'Ground Turkey',
  'Shrimp',
  'Sausage',
  'Pita Bread',
  'Bagels',
  'English Muffins',
  'Couscous',
  'Polenta',
  'Tamarind Paste',
  'Worcestershire Sauce',
  'Sriracha',
  'Barbecue Sauce',
  'Salsa',
  'Capers',
  'Olives',
  'Chicken Thighs',
  'Beef Steaks',
  'Cod Fillets',
  'Egg Whites',
  'Egg Yolks',
  'Cream',
  'Gouda Cheese',
  'Feta Cheese',
  'Sour Cream',
  'Margarine',
  'Canola Oil',
  'Sesame Seeds',
  'Chili Flakes',
  'Paprika',
  'Garlic Salt',
  'Curry Powder',
  'Ground Coriander',
  'Turmeric',
  'Dried Bay Leaves',
  'Dried Dill',
  'Dried Parsley',
  'Dried Chives',
  'Red Pepper Flakes',
  'Szechuan Peppercorns',
  'Coconut Flour',
  'Almond Flour',
  'Breadcrumbs',
  'Cornstarch',
  'Soy Sauce',
  'Tamari',
  'Hoisin Sauce',
  'Teriyaki Sauce',
  'Fish Sauce',
  'Lemon Zest',
  'Orange Zest',
  'Ginger',
  'Shallots',
  'Leeks',
  'Chives',
  'Celery',
  'Butternut Squash',
  'Sweet Potatoes',
  'Cauliflower',
  'Cabbage',
  'Kale',
  'Swiss Chard',
  'Brussels Sprouts',
  'Snow Peas',
  'Green Onions',
  'Shredded Carrots',
  'Cucumber',
  'Radishes',
  'Scallions',
  'Mango',
  'Avocado',
  'Pineapple',
  'Apples',
  'Bananas',
  'Limes',
  'Oranges',
  'Basil Leaves',
  'Rosemary Sprigs',
  'Thyme Sprigs',
  'Chervil',
  'Tarragon',
  'Dijon Mustard',
  'Apple Cider Vinegar',
  'Balsamic Vinegar',
  'White Vinegar',
  'Maple Syrup',
  'Agave Syrup',
  'Molasses',
  'Peanut Butter',
  'Almond Butter',
  'Tahini',
  'Poppy Seeds',
  'Sunflower Seeds',
  'Walnuts',
  'Pecans',
  'Cashews',
  'Pine Nuts',
  'Raisins',
  'Dried Cranberries',
  'Dark Chocolate Chips',
  'White Chocolate Chips',
  'Coconut Flakes',
  'Canned Tuna',
  'Canned Chickpeas',
  'Canned Tomatoes with Green Chilies',
  'Canned Peas'
];

export const ceilingSpecialistEssentials = [
  'Measuring Tape',
  'Laser Level',
  'Plumb Bob',
  'Square',
  'Chalk Line',
  'Utility Knife',
  'Drywall Saw',
  'Reciprocating Saw',
  'Circular Saw',
  'Jigsaw',
  'Cordless Drill',
  'Impact Driver',
  'Stud Finder',
  'Ceiling Tile Cutter',
  'T-Square',
  'Pliers',
  'Wrenches',
  'Screwdrivers',
  'Hammer',
  'Pry Bar',
  'Level',
  'Construction Adhesive',
  'Staple Gun',
  'Nails',
  'Screws',
  'Anchors',
  'Drywall Tape',
  'Joint Compound',
  'Putty Knife',
  'Paint Roller',
  'Paint Brush',
  'Drop Cloths',
  'Dust Mask',
  'Safety Glasses',
  'Hard Hat',
  'Work Gloves',
  'Ladder',
  'Scaffolding',
  'Extension Poles',
  'Ceiling Grid System',
  'Hanging Tools',
  'Ceiling Tile Adhesive',
  'Electrician’s Tape',
  'Cable Clips',
  'Cordless Saw',
  'Drill Bit Set',
  'Speed Square',
  'Dust Collector',
  'Power Sander',
  'Hacksaw',
  'Bench Vice',
  'Pipe Cutter',
  'Strapping Tools',
  'Tie Wire',
  'Panel Lifter',
  'Trowel',
  'Rafter Square',
  'Scriber',
  'Heat Gun',
  'Safety Harness',
  'Fire Extinguisher',
  'Tool Belt',
  'Carpenter’s Pencil',
  'Multimeter',
  'Voltage Tester',
  'Cable Strippers',
  'Pneumatic Nailer',
  'Handheld Vacuum',
  'Portable Light',
  'Extension Cord',
  'Workbench',
  'Dust Extractor',
  'Acoustic Ceiling Tile Cutter',
  'Suspension System Tools',
  'Drywall Lift',
  'Ceiling Fan Installation Tools',
  'Insulation Cutting Tools',
  'Fiberglass Tape Measure',
  'Ceiling Trim Tools',
  'Miter Saw',
  'Corner Bead Tools',
  'Ceiling Tile Alignment Tools',
  'Spray Adhesive',
  'Hanging Rods',
  'Ceiling Panel Lifter',
  'Jamb Saw',
  'Floating Tool',
  'Tile Spacers'
];

export const servicesRequiringPeople = [
  'Chef Service',
  'Tour Guide Service',
  'Travels & Tours Agency',
  'Hoteling & Resort Service'
];
