import {
  usePackageContext,
  usePackageContextAction
} from 'apps/packages/PackageProvider';

export const usePackage = () => {
  const {
    packLoading,
    painterPackages,
    painterPackage,
    chefPackages,
    chefPackage,
    stoneTypePackages,
    ceilingTypePackages,
    tourGuidePackages,
    tourGuidePackage,
    hotelRooms,
    roomReviews,
    packageReviews,
    bookedDates,
    allRooms,
    count,
    next,
    prev
  } = usePackageContext();
  return {
    packLoading,
    bookedDates,

    painterPackages,
    painterPackage,

    stoneTypePackages,

    chefPackages,
    chefPackage,
    ceilingTypePackages,

    tourGuidePackages,
    tourGuidePackage,
    hotelRooms,
    roomReviews,
    packageReviews,

    allRooms,
    count,
    next,
    prev
  };
};

export const usePackageMethod = () => {
  const {
    postCeilingType,
    patchCeilingType,
    getCeilingType,
    delCeilingType,

    getStoneType,
    postStoneType,
    delStoneType,
    patchStoneType,

    postPainterPackage,
    patchPainterPackage,
    delPainterPackage,
    getPainterPackage,
    retrievePainterPackage,

    postTourGuidePackage,
    patchTourGuidePackage,
    delTourGuidePackage,
    getTourGuidePackage,
    retrieveTourGuidePackage,

    getChefOffer,
    retrieveChefOffer,
    postChefOffer,
    patchChefOffer,
    delChefOffer,

    postNewRoom,
    getHotelRooms,
    getRoomDetails,
    getRoomBookedDates,
    getAllRooms,
    editRoomDetails,
    delRoomImage,
    delHotelImage,
    getHotelImage,
    updateHotelImage,
    getRoomReviews,
    getPackageReviews
  } = usePackageContextAction();
  return {
    postCeilingType,
    patchCeilingType,
    getCeilingType,
    delCeilingType,

    getStoneType,
    postStoneType,
    delStoneType,
    patchStoneType,

    postPainterPackage,
    patchPainterPackage,
    getPainterPackage,
    delPainterPackage,
    retrievePainterPackage,

    postTourGuidePackage,
    patchTourGuidePackage,
    delTourGuidePackage,
    getTourGuidePackage,
    retrieveTourGuidePackage,

    getChefOffer,
    retrieveChefOffer,
    postChefOffer,
    patchChefOffer,
    delChefOffer,

    postNewRoom,
    getHotelRooms,
    getRoomDetails,
    getRoomBookedDates,

    getAllRooms,
    editRoomDetails,
    delRoomImage,
    delHotelImage,
    getHotelImage,
    updateHotelImage,
    getRoomReviews,
    getPackageReviews
  };
};
