import React, {createContext, useContext, useEffect, useState} from 'react';
import * as api from '../apis/allApis';
import axiosInstance from '../apis';
import {useAuthMethod, useAuthUser} from '@myservice/hooks/AuthUser';
import {useNotificationMethod} from '@myservice/hooks/useNotification';
import extractError from '@myservice/helper/extractError';

const WorkerContext = createContext();
const WorkerActionsContext = createContext();

const WorkerProvider = ({children}) => {
  const [isLoading, setLoading] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [bussiness, setBussiness] = useState([]);

  const [worker, setWorker] = useState({});
  const [workerById, setWorkerById] = useState({});

  const [searchParam, setSearchParam] = useState({
    query: '',
    service: '',
    searchHotels: false
  });

  const {getUserMe} = useAuthMethod();
  const {isAuthenticated, user} = useAuthUser();
  const {showNotification} = useNotificationMethod();

  const fetchWorker = async (url, method = 'GET', payload = null) => {
    try {
      setLoading(true);
      const response = await axiosInstance({
        method,
        url,
        data: payload
      });
      return response.data;
    } catch (error) {
      const errorMessage =
        extractError(error.response?.data) || 'An error occurred';
      showNotification(errorMessage);
      console.error('API request failed:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getWorkersData = async (name = '', uuid = '') => {
    const data = await fetchWorker(`${api.WORKER}?name=${name}&uuid=${uuid}`);
    setWorkers(data);
    return data;
  };

  const getWorkerData = async () => {
    const data = await fetchWorker(api.CURRENT_WORKER);
    setWorker(data);
  };

  const getWorkerById = async (worker_id) => {
    const data = await fetchWorker(`${api.WORKER_BY_ID}${worker_id}`);
    setWorkerById(data);
    return data;
  };

  const switchAccount = async () => {
    setLoading(true);
    try {
      const resp = await axiosInstance.post(api.SWITCH_ACCOUNT_TO_SERVICE);
      setLoading(false);
      getUserMe();
      return resp;
    } catch (error) {
      setLoading(false);
      showNotification(extractError(error.response.data));
    }
  };

  const postWorkerData = async (payload) => {
    setLoading(true);
    if (worker?.id) {
      try {
        const rep = await axiosInstance.put(
          `${api.WORKER}${worker?.id}/`,
          payload,
          {
            headers: {'Content-Type': 'multipart/form-data'}
          }
        );
        getWorkerData();
        showNotification(
          'You have updated your worker account details successfully',
          'info'
        );
        setLoading(false);
        return rep;
      } catch (error) {
        showNotification(extractError(error.response.data));
        setLoading(false);
      }
    } else {
      try {
        const rep = await axiosInstance.post(api.WORKER, payload, {
          headers: {'Content-Type': 'multipart/form-data'}
        });
        getWorkerData();
        showNotification(
          'You have successfully created worker account with myservice',
          'info'
        );
        setLoading(false);
        return rep;
      } catch (error) {
        console.error('errorerror', error);
        showNotification(extractError(error.response.data));
        setLoading(false);
      }
    }
  };

  const retrieveWorker = async (workerId) => {
    const worker = await fetchWorker(`${api.WORKER}${workerId}`);
    setWorker(worker);
    return worker;
  };

  const getAllBussiness = async () => {
    const resp = await fetchWorker(api.GET_ALL_BUSSINESS);
    setBussiness(resp);
    return resp;
  };

  const getFeatureTravelTours = async () => {
    return await fetchWorker(api.FEATURE_TOUR_GUIDE);
  };

  const getFeaturePainters = async () => {
    return await fetchWorker(api.FEATURE_PAINTERS);
  };

  const getFeaturePackages = async (param) => {
    return await fetchWorker(`${api.FEATURE_PACKAGES}${param}`);
  };

  const getPackages = async () => {
    return await fetchWorker(`${api.PACKAGES}`);
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.role === 'Service Provider') {
        getWorkerData();
      }
    }
    getWorkersData();
  }, [isAuthenticated]);

  return (
    <WorkerContext.Provider
      value={{
        isLoading,
        worker,
        workerById,
        workers,
        searchParam,
        bussiness
      }}
    >
      <WorkerActionsContext.Provider
        value={{
          getPackages,
          switchAccount,
          postWorkerData,
          getWorkerData,
          getWorkerById,
          retrieveWorker,
          getWorkersData,
          setSearchParam,
          getAllBussiness,
          getFeaturePackages,
          getFeaturePainters,
          getFeatureTravelTours
        }}
      >
        {children}
      </WorkerActionsContext.Provider>
    </WorkerContext.Provider>
  );
};

export const useWorkers = () => useContext(WorkerContext);

export const useWorkerActions = () => useContext(WorkerActionsContext);

export default WorkerProvider;
