import {ThemeProvider} from 'styled-components';
import {HelmetProvider} from 'react-helmet-async';
import GlobalStyle from './styles/Global';
import theme from './styles/Theme';
import {Fragment} from 'react';
import Pages from './path/Pages';
import NotificationProvider from './apps/notifications/NotificationsProvider';
import AppAPIProvider from './apps/apis/AppAPIProvider';
import ConnectionStatus from 'path/errorPages/ConnectionStatus';

function App() {
  return (
    <Fragment>
      <HelmetProvider>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <AppAPIProvider>
              <ConnectionStatus />
              <Pages />
            </AppAPIProvider>
          </NotificationProvider>
        </ThemeProvider>
      </HelmetProvider>
    </Fragment>
  );
}

export default App;
