import {
  useOrderContext,
  useOrderActionContext
} from 'apps/orders/OrderProvider';

export const useOrder = () => {
  const {
    allUserOrder,
    workerHiring,
    workerOrders,
    hiring,
    userHiring,
    count,
    orderLoading,
    createOrder,
    dateRange,
    dateTime,
    area,
    numParticipants
  } = useOrderContext();
  return {
    allUserOrder,
    hiring,
    userHiring,

    workerHiring,
    workerOrders,
    count,
    orderLoading,
    createOrder,
    dateRange,
    dateTime,
    area,
    numParticipants
  };
};

export const useOrderMethod = () => {
  const {
    getHiringDetails,
    postOrder,
    postHire,
    getAllHire,
    getAllUserOrder,
    getWorkerHire,
    getWorkerOrders,

    updateQuantity,
    removeItem,
    setCreateOrder,
    setDateRange,
    setDateTime,
    getOrderDetails,
    handleCreateOrder,
    setNumParticipants,
    updateHiringStatus,
    setArea,
    getUserHire,
    updateOrderStatus,
    getDestinationImage
  } = useOrderActionContext();
  return {
    postOrder,
    postHire,
    getAllHire,
    getWorkerHire,
    getWorkerOrders,
    getAllUserOrder,
    updateQuantity,
    removeItem,
    setCreateOrder,
    setDateRange,
    setDateTime,
    getOrderDetails,
    handleCreateOrder,
    setNumParticipants,
    updateHiringStatus,
    getHiringDetails,
    setArea,
    getUserHire,
    updateOrderStatus,
    getDestinationImage
  };
};
