import {useState, useEffect} from 'react';

const useOrderImages = (packageData, serviceName, getDestinationImage) => {
  const [orderImages, setOrderImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      if (!packageData.itineraries?.length) return;

      const images = await Promise.all(
        packageData.itineraries.map(getDestinationImage)
      );

      setOrderImages(images.map((img) => img?.image_url).filter(Boolean));
    };

    if (
      ['Tour Guide Service', 'Travels & Tours Agency'].includes(serviceName)
    ) {
      fetchImages();
    } else if (serviceName === 'Hoteling & Resort Service') {
      packageData?.images?.map((img) => {
        setOrderImages((prev) => [...prev, `${img?.image}`]);
      });
    }
  }, []);

  return orderImages;
};

export default useOrderImages;
