import {
  useReviewActionContext,
  useReviewContext
} from 'apps/reviews/ReviewProvider';

export const useReview = () => {
  const {reviewLoading, testimonies, workerReview, workerTestimonies, reviews} =
    useReviewContext();
  return {reviewLoading, testimonies, workerReview, workerTestimonies, reviews};
};

export const useReviewMethod = () => {
  const {
    postPackageReview,
    postRoomReview,
    postStartReview,
    patchStartReview,
    getAllTestimonies,
    getWorkerReviews,
    getAllReviews,
    getWorkerTestimonies
  } = useReviewActionContext();
  return {
    postPackageReview,
    postRoomReview,
    postStartReview,
    patchStartReview,
    getAllTestimonies,
    getWorkerReviews,
    getAllReviews,
    getWorkerTestimonies
  };
};
