import {WifiSignal} from 'assests/icons';
import React, {useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import SvgComp from 'styles/common';

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  animation: ${slideUp} 0.3s ease-out;
`;

const Container = styled.div`
  background-color: #fef2f2;
  padding: 1rem;
`;

const Content = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const Message = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: #991b1b;
`;

const ReloadButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #fee2e2;
  padding: 0.5rem 1.4rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #b91c1c;
  transition: background-color 0.2s;
  border: none;
  outline: none;

  &:hover {
    background-color: #fecaca;
  }
`;

const ConnectionStatus = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    setIsOnline(navigator.onLine);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (isOnline) return null;

  return (
    <Banner>
      <Container>
        <Content>
          <MessageWrapper>
            <SvgComp
              Icon={<WifiSignal />}
              size='1.5rem'
              stroke={'#991b1b'}
              fillColor={'transparent'}
              strokeWidth={2}
            />
            <Message>
              No internet connection. Some features may be unavailable.
            </Message>
          </MessageWrapper>
          <ReloadButton onClick={() => window.location.reload()}>
            Reload
          </ReloadButton>
        </Content>
      </Container>
    </Banner>
  );
};

export default ConnectionStatus;
