import React, {createContext, useContext, useState} from 'react';

import extractError from '@myservice/helper/extractError';
import {useNotificationMethod} from '@myservice/hooks';
import axiosInstance from 'apps/apis';
import {ACCOMMODATION, FEATURE_ACCOMMODATION, WORKER} from 'apps/apis/allApis';

const AccommodationContext = createContext();
const AccommodationAction = createContext();

export const AccommodationProvider = ({children}) => {
  const {showNotification} = useNotificationMethod();

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [accommodationState, setAccommodationState] = useState({
    accommodations: [],
    previous: null,
    next: null,
    count: 0,
    currentPage: 1
  });

  const [featureAccommodation, setFeatureAccommodation] = useState({
    accommodations: [],
    previous: null,
    next: null,
    count: 0,
    currentPage: 1
  });

  const fetchData = async (url, method = 'GET', payload = null, isFormData) => {
    try {
      setLoading(true);
      const response = await axiosInstance({
        method,
        url,
        data: payload,
        headers: isFormData ? {'Content-Type': 'multipart/form-data'} : {}
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      showNotification(extractError(error.response.data));
      console.error(error);
      return error;
    }
  };

  const getAllCites = async () => {
    const cities = await fetchData(`${WORKER}cities`);
    setCities(cities);
    return cities;
  };

  const getFeatureAccommodations = async (filters = {}) => {
    const page = featureAccommodation.next
      ? featureAccommodation.currentPage + 1
      : featureAccommodation.currentPage;

    const queryParams = new URLSearchParams({
      page,
      page_size: 8,
      ...filters // Add dynamic filters like name
    });

    const url = `${FEATURE_ACCOMMODATION}?${queryParams.toString()}`;

    const data = await fetchData(url);
    if (!data) return null;

    setFeatureAccommodation((prev) => ({
      ...prev,
      accommodations:
        page > 1
          ? [
              ...prev.accommodations,
              ...(data.results || []).filter(
                (newAccom) =>
                  !prev.accommodations.some(
                    (existingAccom) => existingAccom.id === newAccom.id
                  )
              )
            ]
          : data.results || [],
      previous: data.previous,
      next: data.next,
      count: data.count || 0,
      currentPage: page
    }));

    return data;
  };

  const getAccommodations = async (filters = {}, requestedPage = null) => {
    try {
      const wasFilteringByName = accommodationState.currentFilter?.name;
      const isFilteringByName = filters.name;

      // Reset to page 1 when filters change
      const page =
        isFilteringByName || wasFilteringByName !== isFilteringByName
          ? 1
          : requestedPage ||
            (accommodationState.next
              ? accommodationState.currentPage + 1
              : accommodationState.currentPage);

      const queryParams = new URLSearchParams({
        page,
        page_size: 8,
        ...filters
      });

      setLoading(true);
      const url = `${ACCOMMODATION}?${queryParams.toString()}`;
      const data = await fetchData(url);

      if (!data || !data.results) {
        throw new Error('Invalid data received');
      }

      setAccommodationState((prev) => {
        // For page 1 or filter changes, replace the entire list
        if (page === 1) {
          return {
            ...prev,
            accommodations: data.results,
            previous: data.previous,
            next: data.next,
            count: data.count || 0,
            currentPage: page,
            currentFilter: filters
          };
        }

        // For subsequent pages, append new unique items
        const newAccommodations = [
          ...prev.accommodations,
          ...data.results.filter(
            (newAccom) =>
              !prev.accommodations.some(
                (existingAccom) => existingAccom.id === newAccom.id
              )
          )
        ];

        return {
          ...prev,
          accommodations: newAccommodations,
          previous: data.previous,
          next: data.next,
          count: data.count || 0,
          currentPage: page,
          currentFilter: filters
        };
      });

      return {
        ...data,
        page_size: 8 // Include page_size in response for scroll handling
      };
    } catch (error) {
      console.error('Error fetching accommodations:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <AccommodationContext.Provider
      value={{accommodationState, featureAccommodation, cities, loading}}
    >
      <AccommodationAction.Provider
        value={{
          getAllCites,
          getAccommodations,
          getFeatureAccommodations
        }}
      >
        {children}
      </AccommodationAction.Provider>
    </AccommodationContext.Provider>
  );
};

export const useAccommodationContext = () => useContext(AccommodationContext);
export const useAccommodationActions = () => useContext(AccommodationAction);

export default AccommodationProvider;
