import React, {useEffect, useCallback, useRef} from 'react';
import styled from 'styled-components';
import {useSpring, animated} from 'react-spring';
import {useDrag} from '@use-gesture/react';
import {CustomButton} from '../Button/CustomButton';

const DialogContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${({padding}) => padding ?? '1rem'};
`;

const FormWrapper = styled.div`
  p {
    font-size: 1.15rem;
    font-weight: 1rem;
  }
`;

const CustomButtonWrapper = styled.div`
  border-top: 2px solid ${({theme}) => theme.bg[400]};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem;
`;

const BottomSheet = styled(animated.div)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 1.8rem;
  border-top-right-radius: 1.8rem;
  touch-action: none;
  overflow-y: auto;
  border-top: 0.1rem solid ${({theme}) => theme.bg[400]};
  z-index: 9999999999;
`;

const DragHandle = styled.div`
  touch-action: none;
  margin-top: 0.75rem;
  padding: 1rem;
  > span {
    display: block;
    border-radius: 1rem;
    margin: 0.2rem auto;
    background-color: ${({theme}) => theme.gray[300]};
    border-radius: 0.4rem;
    width: 2rem;
    height: 0.2rem;
  }
`;

const MobileDrag = ({
  setOpenDialog,
  openDialog,
  setIsMobile,
  isMobile,
  padding,
  btnText,
  handleSubmit,
  button,
  children
}) => {
  const dragStartY = useRef(0);
  const sheetRef = useRef(null);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const [{y}, api] = useSpring(() => ({y: window.innerHeight}));

  const SHEET_HEIGHTS = {
    MIN: 100,
    MID: window.innerHeight * 0.5,
    MAX: window.innerHeight
  };

  useEffect(() => {
    if (openDialog && isMobile) {
      api.start({y: SHEET_HEIGHTS.MID, immediate: false});
    } else {
      api.start({y: SHEET_HEIGHTS.MAX, immediate: false});
    }
  }, [openDialog, isMobile, api, SHEET_HEIGHTS.MID, SHEET_HEIGHTS.MAX]);

  const close = useCallback(() => {
    api.start({y: SHEET_HEIGHTS.MAX, immediate: false});
    setTimeout(() => setOpenDialog(false), 300);
  }, [setOpenDialog, api, SHEET_HEIGHTS.MAX]);

  const bind = useDrag(
    ({first, last, movement: [, my], velocity: [, vy], direction: [, dy]}) => {
      if (first) {
        dragStartY.current = y.get();
      }

      const currentY = dragStartY.current + my;
      const currentPosition = window.innerHeight - currentY;

      if (!last) {
        api.start({
          y: Math.max(0, Math.min(currentY, SHEET_HEIGHTS.MAX)),
          immediate: true
        });
      } else {
        if (vy > 0.5 && dy > 0) {
          // Swipe down
          if (currentPosition < SHEET_HEIGHTS.MID) {
            close();
          } else {
            api.start({y: SHEET_HEIGHTS.MID, immediate: false});
          }
        } else if (vy > 0.5 && dy < 0) {
          // Swipe up
          api.start({y: 0, immediate: false});
        } else {
          // Snap to nearest position
          if (currentPosition < SHEET_HEIGHTS.MIN * 1.5) {
            close();
          } else if (
            currentPosition <
            (SHEET_HEIGHTS.MID + SHEET_HEIGHTS.MIN) / 2
          ) {
            api.start({y: SHEET_HEIGHTS.MID, immediate: false});
          } else {
            api.start({y: 0, immediate: false});
          }
        }
      }
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: {top: 0, bottom: SHEET_HEIGHTS.MAX},
      rubberband: true
    }
  );

  return (
    <BottomSheet
      ref={sheetRef}
      {...bind()}
      style={{
        y,
        height: y.to((y) => `${window.innerHeight - 2}rem`),
        maxHeight: '100vh'
      }}
    >
      <DragHandle>
        <span></span>
        <span></span>
      </DragHandle>
      <DialogContent padding={padding}>
        <FormWrapper>{children}</FormWrapper>
      </DialogContent>
      {button ?? (
        <CustomButtonWrapper>
          <CustomButton
            onClick={handleSubmit}
            text={btnText ?? 'Save'}
            sx={{
              padding: '0.6rem 1rem'
            }}
          />
        </CustomButtonWrapper>
      )}
    </BottomSheet>
  );
};

export default MobileDrag;
