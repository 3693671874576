// // Custom hook for infinite scrolling
// import {useState, useCallback, useEffect, useRef} from 'react';

// const useInfiniteScroll = (fetchFunction, query) => {
//   const [loading, setLoading] = useState(false);
//   const [hasMore, setHasMore] = useState(true);
//   const lastItemRef = useRef(null);

//   const fetchItems = useCallback(async () => {
//     if (loading) return;

//     setLoading(true);
//     try {
//       // Artificial 2-second delay
//       // await new Promise((resolve) => setTimeout(resolve, 2000));
//       const response = await fetchFunction(query);

//       // If no response, stop loading and indicate no more items
//       if (!response) {
//         setLoading(false);
//         setHasMore(false);
//         return;
//       }

//       // Update hasMore based on the presence of a next page
//       setHasMore(!!response.next);
//     } catch (error) {
//       console.error('Error fetching items:', error);
//       setHasMore(false);
//     } finally {
//       setLoading(false);
//     }
//   }, [fetchFunction, loading]);

//   // Intersection Observer setup
//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         if (entries[0].isIntersecting && hasMore && !loading) {
//           fetchItems();
//         }
//       },
//       {
//         root: null,
//         rootMargin: '20px',
//         threshold: 0.1
//       }
//     );

//     if (lastItemRef.current) {
//       observer.observe(lastItemRef.current);
//     }

//     return () => {
//       if (observer) observer.disconnect();
//     };
//   }, [fetchItems, hasMore, loading]);

//   // Initial fetch
//   useEffect(() => {
//     fetchItems();
//   }, [query?.name]);

//   return {
//     loading,
//     hasMore,
//     lastItemRef
//   };
// };

// export default useInfiniteScroll;

import {useState, useCallback, useEffect, useRef} from 'react';

const useInfiniteScroll = (fetchFunction, query) => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const lastItemRef = useRef(null);

  // Keep track of the current query to detect changes
  const queryRef = useRef(query);

  // Reset scroll state when query changes
  useEffect(() => {
    if (JSON.stringify(queryRef.current) !== JSON.stringify(query)) {
      setHasMore(true);
      setError(null);
      queryRef.current = query;
    }
  }, [query]);

  const fetchItems = useCallback(async () => {
    // Prevent concurrent fetches
    if (loading || !hasMore) return;

    setLoading(true);
    setError(null);

    try {
      const response = await fetchFunction(query);

      // Handle no response or empty results
      if (!response || !response.results) {
        setHasMore(false);
        return;
      }

      // Check if we've reached the end of the data
      setHasMore(!!response.next && response.results.length > 0);

      // If the response indicates no more pages or returns fewer items than requested,
      // we've reached the end
      if (
        !response.next ||
        response.results.length < (response.page_size || 8)
      ) {
        setHasMore(false);
      }
    } catch (error) {
      setError(error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [fetchFunction, query, loading, hasMore]);

  // Intersection Observer setup with improved threshold handling
  useEffect(() => {
    const currentRef = lastItemRef.current;

    if (!currentRef) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasMore && !loading) {
          fetchItems();
        }
      },
      {
        root: null,
        rootMargin: '100px', // Increased margin for earlier loading
        threshold: 0.1
      }
    );

    observer.observe(currentRef);

    return () => {
      if (currentRef) {
        observer.disconnect();
      }
    };
  }, [fetchItems, hasMore, loading]);

  // Initial fetch only when query changes
  useEffect(() => {
    setHasMore(true); // Reset hasMore state
    fetchItems();
  }, [query?.name]);

  return {
    loading,
    hasMore,
    error,
    lastItemRef
  };
};

export default useInfiniteScroll;
