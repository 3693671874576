import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'styles/global.css'; // Import global.css

const root = ReactDOM.createRoot(document.getElementById('root'));

const GoogleAnalytics = () => {
  useEffect(() => {
    if (window.gtag) return;

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-J59HPEQ6NR';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // Initialize Google Analytics
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-J59HPEQ6NR');
    };
  }, []);

  return null;
};

root.render(
  <React.StrictMode>
    <GoogleAnalytics />
    <App />
  </React.StrictMode>
);
