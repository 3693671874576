import React, {Suspense} from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {routes} from './index';
import {CenterLoader} from 'styles/Loading';

const Pages = () => {
  const allRoute = createBrowserRouter(routes, {basename: '/'});

  return (
    <Suspense fallback={<CenterLoader />}>
      <RouterProvider router={allRoute} />
    </Suspense>
  );
};

export default React.memo(Pages);
